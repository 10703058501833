











































































































import { Vue, Component, PropSync, Watch } from "vue-property-decorator";

import { PlannerConfigData } from "@/interfaces";

@Component({
    components: {
        OptionChart: () => import("@/components/charts/OptionChart.vue")
    }
})
export default class PlanConfiguration extends Vue {
    @PropSync("planData", { type: Object })
    data!: PlannerConfigData;

    skeleton = false;

    @Watch("data.creationDate")
    async onDataChange() {
        if (this.data.creationDate != "") {
            this.skeleton = false;
            return;
        }
        this.skeleton = true;
    }

    mounted() {
        if (this.data.creationDate != "") {
            this.skeleton = false;
            return;
        }
        this.skeleton = true;
    }
}
